<template>
    <div>
        <div class="text-center">
            <h6>Smart Contract Auditor</h6>
            <p class="text-center my-25 text-lg text-muted custom-description">Please input your smart contract
            </p>

            <!-- textarea -->
            <a-form-item label="Smart Contract">
                <template slot="extra">
                    <a-tooltip placement="topLeft" title="Please input your Smart Contract !">
                        <a-icon type="info-circle" style="color: #c50b0b" />
                        <span class="icon-text ml-3">Please input your Smart Contract !</span>
                    </a-tooltip>
                </template>
                <a-textarea rows="15" v-model="step2Data.smart_contract" @blur="updateData2"
                    v-decorator="['smart_contract', { rules: [{ required: true, message: 'Please input your Smart Contract !' }] }]" />
            </a-form-item>
            <!-- end textarea -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            step2Data: {
                smart_contract: ``,
            },
        };
    },
    mounted() {
    },
    watch: {
        step2Data(newVal) {
            this.$emit('updateData2', newVal);
        }
    },
    methods: {
        updateData2() {
            this.$emit('updateData2', this.step2Data);
        },
    },
};
</script>
<style scoped>
.icon-text {
    margin-left: 2px !important;
}
</style>