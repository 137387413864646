<!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div>
		<!-- form wizzard -->
		<MultiStepForm></MultiStepForm>
		<div class="loading" v-if="loading">
		</div>

	</div>
</template>

<script>

import MultiStepForm from '../components/Wizzard/MultiStepForm';

export default ({
	components: {
		MultiStepForm,
	},
	data() {
		return {
			loading: false,
		}
	},
	beforeCreate() {
	},
	mounted() {
		this.serviceApi = this.$dotenv.VUE_APP_SERVICE_API;
		this.tokenAddress = this.$dotenv.VUE_APP_TOKEN_ADDRESS;
	},
	created() {
		this.serviceApi = this.$dotenv.VUE_APP_SERVICE_API;
		this.tokenAddress = this.$dotenv.VUE_APP_TOKEN_ADDRESS;
	},
	methods: {

	},
})

</script>

<style lang="scss" scoped>
.custom-title-left {
	text-transform: uppercase;

}

.icon-text {
	// text with icon in left
	position: relative;
	top: 2px;
	margin-right: 5px;


}

.text {
	font-size: 12px;
	position: relative;
	font-weight: 700;


}

.loading {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.5);
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>