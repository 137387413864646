<template>
    <div class="text-center container-finish">
        <!-- finish submit and wailting 1/2 hours to get result-->
        <a-card class="card-audit-finish">
            <div class="text-center">
                <h6 class="my-25 text-lg  custom-description">Finish submit and waiting 1/2 hours to get result
                </h6>
                <p class="text-center my-25 text-lg text-muted  custom-description">Please wait for the result
                </p>
            </div>

        </a-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            step3Data: {
                smart_contract: '',
            },
        };
    },
    methods: {
        updateData() {
            this.$emit('updateData', this.step3Data);
        },
    },
};

</script>
<style>
.container-finish {
    margin-bottom: 20px;
}

.card-audit-finish {
    background: transparent;
    border: 0;
    box-shadow: none;
    padding: 0;

}
</style>